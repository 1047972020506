import axios from "axios";
import secureLocalStorage from "react-secure-storage";

import { refresh } from "./authentication";

const baseURL = "";

const _axios = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "ar",
  },
});

_axios.interceptors.request.use(
  (config) => {
    const token = secureLocalStorage.getItem("userToken");

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

_axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error.config;

    if (error.response?.status === 401 && !config._retry) {
      config._retry = true;

      try {
        const result = await refresh();

        if (result?.token) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${result?.token}`,
          };

          return _axios(config);
        }
      } catch (error) {
        window.location.href = "/authentication";
        secureLocalStorage.clear();
      }

      return Promise.reject({
        error: error.response,
        message: "حدث خطأ, الرجاء المحاولة في وقت لاحق",
      });
    }

    return Promise.reject({
      error: error.response,
      message: "حدث خطأ, الرجاء المحاولة في وقت لاحق",
    });
  },
);

export default _axios;
