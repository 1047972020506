import { useState, useContext, createContext } from "react";

// initial authentication values for auto-completion purposes.
const AuthenticationContext = createContext({
  mobileNumber: "",
  setMobileNumber: () => {},
  token: null,
  setToken: () => {},
  otpConfig: {
    otpTimer: "",
    otpLength: 0,
  },
  setOtpConfig: () => {},
  otpErrorMessage: "",
  setOtpErrorMessage: () => {},
});

const AuthenticationProvider = ({ children }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [token, setToken] = useState(null);
  const [otpConfig, setOtpConfig] = useState({
    otpTimer: "",
    otpLength: 0,
  });
  const [otpErrorMessage, setOtpErrorMessage] = useState("");

  const authenticationContextValue = {
    mobileNumber,
    setMobileNumber,
    token,
    setToken,
    otpConfig,
    setOtpConfig,
    otpErrorMessage,
    setOtpErrorMessage,
  };

  return (
    <AuthenticationContext.Provider value={authenticationContextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = () => useContext(AuthenticationContext);

export { AuthenticationContext, AuthenticationProvider, useAuthentication };
