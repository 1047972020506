const PhoneIcon = () => {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2702 19.9609C10.6802 19.9609 11.0202 19.6209 11.0202 19.2109C11.0202 18.8009 10.6802 18.4609 10.2702 18.4609H7.24023C6.83023 18.4609 6.49023 18.8009 6.49023 19.2109C6.49023 19.6209 6.83023 19.9609 7.24023 19.9609H10.2702Z"
        fill="#606060"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.44 18.1C17.51 17.24 17.51 16.16 17.51 14.79V8.72C17.51 7.35 17.51 6.27 17.44 5.41C17.37 4.53 17.22 3.8 16.88 3.14C16.33 2.06 15.45 1.18 14.37 0.63C13.71 0.29 12.98 0.15 12.1 0.07C11.24 0 10.17 0 8.79 0H8.72C7.35 0 6.27 0 5.41 0.07C4.53 0.14 3.8 0.29 3.14 0.63C2.06 1.18 1.18 2.06 0.63 3.14C0.29 3.8 0.15 4.53 0.07 5.41C0 6.27 0 7.35 0 8.72V14.79C0 16.16 0 17.24 0.07 18.1C0.14 18.98 0.29 19.71 0.63 20.37C1.18 21.45 2.06 22.33 3.14 22.88C3.8 23.22 4.53 23.36 5.41 23.44C6.27 23.51 7.35 23.51 8.72 23.51H8.79C10.16 23.51 11.24 23.51 12.1 23.44C12.98 23.37 13.71 23.22 14.37 22.88C15.45 22.33 16.33 21.45 16.88 20.37C17.22 19.71 17.36 18.98 17.44 18.1ZM1.58 17.98C1.52 17.18 1.51 16.17 1.51 14.76V8.76C1.51 7.35 1.51 6.33 1.58 5.54C1.64 4.75 1.77 4.24 1.98 3.83C2.39 3.03 3.04 2.38 3.84 1.97C4.25 1.76 4.76 1.64 5.55 1.57C6.35 1.5 7.36 1.5 8.77 1.5C10.18 1.5 11.2 1.5 11.99 1.57C12.78 1.63 13.29 1.76 13.7 1.97C14.5 2.38 15.15 3.03 15.56 3.83C15.77 4.24 15.89 4.75 15.96 5.54C16.03 6.34 16.03 7.35 16.03 8.76V14.76C16.03 16.17 16.03 17.19 15.96 17.98C15.9 18.77 15.77 19.28 15.56 19.69C15.15 20.49 14.5 21.14 13.7 21.55C13.29 21.76 12.78 21.88 11.99 21.95C11.19 22.01 10.18 22.02 8.77 22.02C7.36 22.02 6.34 22.02 5.55 21.95C4.76 21.89 4.25 21.76 3.84 21.55C3.04 21.14 2.39 20.49 1.98 19.69C1.77 19.28 1.65 18.77 1.58 17.98Z"
        fill="#606060"
      />
    </svg>
  );
};

export default PhoneIcon;
