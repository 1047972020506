// 1... importing React specific APIs.
import { useRef } from "react";

export default function InputOTP({ otp, onChange, isValid }) {
  const numberOfDigits = 6;
  const otpBoxReference = useRef([]);

  function handleChange(value, index) {
    const newValue = value.replace(/[^\d]/g, "");
    if (newValue) {
      let newArr = [...otp];
      newArr[index] = newValue;
      if (index < numberOfDigits - 1) {
        newArr[index + 1] = "";
        otpBoxReference.current[index + 1].focus();
      }
      onChange(newArr);
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    } else if (e.key === "Backspace" && e.target.value && index >= 0) {
      let newArr = [...otp];
      newArr[index] = "";
      onChange(newArr);
      if (index !== 0) {
        otpBoxReference.current[index - 1].focus();
      }
    }
  }

  return (
    <div className="relative w-full">
      <div
        dir="ltr"
        className="flex flex-row gap-3 items-center justify-between mx-auto w-full"
      >
        {otp.map((digit, index) => (
          <input
            key={index}
            value={digit}
            maxLength={1}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
            ref={(reference) => (otpBoxReference.current[index] = reference)}
            placeholder="-"
            className={`w-full h-full text-center px-2 sm:px-4 py-2 outline-none rounded-lg border-0 bg-white focus:ring-1 ring-grayDark ${!isValid ? "border-2 border-primary" : undefined
              }`}
          />
        ))}
      </div>
    </div>
  );
}
