export default function Success() {
  return (
    <div className="flex flex-col justify-center items-center text-center gap-7">
      <div className="flex flex-col gap-3">
        <span className="text-sm">تم حذف حسابك بنجاح</span>
      </div>

      <svg
        width="73"
        height="73"
        viewBox="0 0 73 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="text-success"
      >
        <path
          d="M55.374 28.0125C56.3121 26.9647 56.2231 25.3548 55.1753 24.4167C54.1275 23.4786 52.5176 23.5675 51.5795 24.6154L40.14 37.3927C37.822 39.9817 36.261 41.7162 34.9263 42.838C33.6549 43.9066 32.9082 44.1395 32.2558 44.1395C31.6035 44.1395 30.8567 43.9066 29.5853 42.838C28.2507 41.7162 26.6896 39.9817 24.3716 37.3927L21.4205 34.0964C20.4824 33.0486 18.8725 32.9597 17.8247 33.8978C16.7768 34.8359 16.6879 36.4458 17.626 37.4936L20.7032 40.9306C22.8621 43.3422 24.6629 45.3537 26.3084 46.7367C28.0489 48.1997 29.9264 49.2326 32.2558 49.2326C34.5853 49.2326 36.4627 48.1997 38.2033 46.7367C39.8487 45.3538 41.6494 43.3423 43.8083 40.9308L55.374 28.0125Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.5 0C16.3416 0 0 16.3416 0 36.5C0 56.6584 16.3416 73 36.5 73C56.6584 73 73 56.6584 73 36.5C73 16.3416 56.6584 0 36.5 0ZM5.09302 36.5C5.09302 19.1544 19.1544 5.09302 36.5 5.09302C53.8456 5.09302 67.907 19.1544 67.907 36.5C67.907 53.8456 53.8456 67.907 36.5 67.907C19.1544 67.907 5.09302 53.8456 5.09302 36.5Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
}
