// 1... importing APIConfig file which holds all our end-points.
import secureLocalStorage from "react-secure-storage";

import { APIConfig } from "./api-config";
import axios from "../axios.config";
// 2... importing mixpanel to tracking API calls.

/* ================ B) Registration APIs: START ================ */
export const SendOtp = async (mobileNumber) => {
  const url = APIConfig.SendOtp.url();

  try {
    const response = await axios.post(url, {
      data: {
        mobileNumber: mobileNumber,
        isForgotPassword: true,
        email: "",
      },
    });

    const responseData = response.data;

    if (responseData.statusCode !== 1) {
      throw new Error(responseData.message);
    }

    return responseData;
  } catch ({ error, message }) {
    throw new Error(error?.data?.message || message);
  }
};

export const VerifyOtp = async (data) => {
  const url = APIConfig.VerifyOtp.url();

  try {
    const response = await axios.post(url, data);

    const responseData = response.data;

    if (responseData.token) {
      secureLocalStorage.setItem("token", responseData.token);
    }

    return responseData;
  } catch ({ error, message }) {
    throw new Error(error?.data?.message || message);
  }
};

export const CreateAccount = async (userInfo) => {
  const url = APIConfig.CreateAccount.url();

  const { userName, passwordOne, passwordTwo, mobileNumber } = userInfo;
  const userToken = secureLocalStorage.getItem("token");

  const body = {
    token: userToken,
    mobileNumber: mobileNumber,
    fullName: userName,
    newPassword: passwordOne,
    confirmPassword: passwordTwo,
  };

  try {
    const response = await axios.post(url, body);

    const responseData = response.data;

    return responseData;
  } catch ({ error, message }) {
    if (error) {
      const { data, status } = error;

      throw new Error(data.message);
    } else if (error?.request) {
      throw new Error("No response received from the server");
    } else {
      throw new Error(message);
    }
  }
};
/* ================ B) Registration APIs:  END  ================ */

/* ================ C) Reset Password APIs: START ================ */
export const forgetPassword = async (msisdn) => {
  const url = APIConfig.forgetPassword.url(msisdn);

  try {
    const response = await axios.get(url);

    const responseData = response.data;

    return responseData;
  } catch ({ error, message }) {
    throw new Error(error?.data.message || message);
  }
};

export const setPassword = async (payload) => {
  const url = APIConfig.setPassword.url();

  try {
    const response = await axios.post(url, payload);

    const responseData = response.data;

    return responseData;
  } catch ({ error, message }) {
    if (error) {
      const { data, status } = error;

      throw new Error(data.message);
    } else if (error?.request) {
      throw new Error("No response received from the server");
    } else {
      throw new Error(message);
    }
  }
};
/* ================ C) Reset Password APIs:  END  ================ */

/* ================ D) Disclaimer APIs: START ================ */
export const checkDisclaimer = async (AcceptTerms) => {
  const url = APIConfig.checkDisclaimer.url(AcceptTerms);

  try {
    const response = await axios.put(url);

    const responseData = response.data;

    return responseData;
  } catch ({ error, message }) {
    throw new Error(error?.data.message || message);
  }
};
/* ================ D) Disclaimer APIs:  END  ================ */

/* ================ E) Onboarding APIs: START ================ */
export const checkOnboarding = async (IsOnboareded) => {
  const url = APIConfig.checkOnboarding.url();

  try {
    const response = await axios.put(url, {
      IsOnboareded,
    });

    const responseData = response.data;

    return responseData;
  } catch ({ error, message }) {
    throw new Error(error?.data.message || message);
  }
};
/* ================ E) Onboarding APIs:  END  ================ */

/* ================ F) Refresh Token APIs: START ================ */
export const refresh = async () => {
  const url = APIConfig.refresh.url();
  const refreshToken = secureLocalStorage.getItem("refreshToken");

  try {
    const response = await axios.post(url, {
      refreshToken,
    });

    const responseData = response.data;

    if (!responseData?.token) {
      secureLocalStorage.removeItem("userToken");
      secureLocalStorage.removeItem("refreshToken");
    }

    secureLocalStorage.setItem("userToken", responseData?.token);
    secureLocalStorage.setItem("refreshToken", responseData?.refreshToken);
    secureLocalStorage.setItem("acceptTerms", responseData?.acceptTerms);
    secureLocalStorage.setItem("isOnboarded", responseData.isOnboarded);

    return responseData;
  } catch ({ error, message }) {
    secureLocalStorage.removeItem("userToken");
    secureLocalStorage.removeItem("refreshToken");
    secureLocalStorage.removeItem("acceptTerms");
    secureLocalStorage.removeItem("isOnboarded");

    throw new Error(error?.data.message || message);
  }
};
/* ================ F) Refresh Token APIs:  END  ================ */

/* ================ G) deleteAccountExternal APIs: START ================ */
export const deleteAccount = async (data) => {
  const url = APIConfig.DeleteAccount.url();

  try {
    const response = await axios.delete(url, {
      data,
    });

    const responseData = response.data;

    return responseData;
  } catch ({ error, message }) {
    throw new Error(error?.data.message || message);
  }
};
/* ================ G) deleteAccountExternal APIs:  END  ================ */
