const baseURL = "https://hsbc-marketplace-api-prod.dsquares.com/api/DynamicApp";

export const APIConfig = {
  VerifyOtp: {
    url: () => `${baseURL}/v1.0/Account/VerifyOTP`,
  },
  SendOtp: {
    url: () => `${baseURL}/v1.0/Account/SendOTP`,
  },
  DeleteAccount: {
    url: () => `${baseURL}/v1.0/Account/DeleteExternal`,
  },
};
