import { useTranslation } from "react-i18next";
import PhoneIcon from "./icons/PhoneIcon";

export default function InputMSISDN({
  onChange,
  onBlur,
  ErrorMessage = null,
  isDisabled = false,
}) {
  const { t: translate } = useTranslation();

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    e.target.value = value;
    onChange(value);
  };

  return (
    <div className="w-full">
      <div className="relative">
        <span className="absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center">
          {/* Icon at the beginning */}
          <div className="ps-3">
            <PhoneIcon />
          </div>
        </span>
        {/* Input text only accepts positive numbers */}
        <input
          className={`block w-full py-2 ps-10 rounded-md bg-mobilawy-[#F6F7F7] text-mobilawy-[#6D6E71] border-2 focus-visible:outline-none ${ErrorMessage
              ? "border-primary"
              : "border-gray focus:ring-1 focus:ring-grayDark"
            }`}
          name="msisdn"
          id="user-msisdn"
          type="text"
          disabled={isDisabled}
          placeholder="ادخل رقم الهاتف"
          maxLength={11}
          onChange={handleInputChange}
          onBlur={onBlur}
        />
      </div>
      {ErrorMessage && (
        <div className="text-primary text-xs mt-1">{ErrorMessage}</div>
      )}
    </div>
  );
}
