import { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { VerifyOtp as VerifyOtpService } from "../../../../services/authentication";

import { useAuthentication } from "../../../../context/authentication-context";

import InputOTP from "../../../../components/InputOTP";
import Alert from "../../../../components/Alert";
import FormButton from "../../../../components/FormButton";

export default function VerifyOtp({ moveToNextStep }) {
  const { mobileNumber, setToken, otpErrorMessage } = useAuthentication();

  // format mobile number like this +(20) 012 xxx xx45
  const formattedMobileNumber = `+(20) ${mobileNumber.slice(0, 3)} xxx xx${mobileNumber.slice(9, 11)}`;

  const [OTP, setOTP] = useState(new Array(6).fill(""));
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const handleOTPChange = (newOTP) => {
    setOTP(newOTP);
    setIsErrorVisible(false);
  };

  const onVerifyOTPSuccess = (response) => {
    if (response.statusCode === 8) {
      setIsErrorVisible(true);
      setErrorMessage(response.message);
      return;
    }
    setToken(response.result.token);
    moveToNextStep();
  };

  const { mutate, isPending, isError } = useMutation({
    mutationFn: () =>
      VerifyOtpService({
        data: {
          otpNumber: Number(OTP.join("")),
          mobileNumber,
          email: "",
        },
      }),
    onSuccess: onVerifyOTPSuccess,
  });

  return (
    <div className="flex flex-col gap-4">
      {isErrorVisible && errorMessage && (
        <Alert errorMessage={errorMessage || otpErrorMessage}></Alert>
      )}
      <span className="text-base font-semibold">تحقق من رقم الهاتف</span>
      <div className="text-sm font-medium text-mobilawy-[#6D6E71]">
        <span>لقد ارسلنا رمزا مكونا من 6 ارقام الي رقم الهاتف</span>
        <br />
        <span dir="ltr" className="text-primary">
          {formattedMobileNumber}
        </span>
      </div>

      <div>
        <InputOTP
          otp={OTP}
          onChange={handleOTPChange}
          isValid={!isError || !isErrorVisible}
        />
      </div>

      <FormButton
        onClick={mutate}
        isDisabled={isPending || OTP.includes("")}
        isLoading={isPending}
        btnText={"تحقق"}
      />
    </div>
  );
}
