import { useState } from "react";

import CallCenter from "../../components/CallCenter";
import VerifyMsisdn from "./components/steps/VerifyMsisdn";
import VerifyOtp from "./components/steps/VerifyOtp";
import Deletion from "./components/steps/Deletion";
import Success from "./components/steps/Success";

import { AuthenticationProvider } from "../../context/authentication-context";

import Logo from "../../components/icons/logo.png";

export default function DeleteAccount() {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    <VerifyMsisdn key="verifyMsisdn" moveToNextStep={moveToNextStep} />,
    <VerifyOtp key="verifyOtp" moveToNextStep={moveToNextStep} />,
    <Deletion
      key="deleteAccount"
      moveToNextStep={moveToNextStep}
      moveToSpecificStep={moveToSpecificStep}
    />,
    <Success key="success" />,
  ];

  function moveToNextStep() {
    setCurrentStep((prev) => {
      return prev + 1;
    });
  }

  function moveToSpecificStep(step) {
    setCurrentStep(step);
  }

  return (
    <div dir="rtl" className="bg-background min-h-screen">
      <div className="h-screen overflow-auto flex items-center justify-center">
        <div className="my-auto w-full">
          <section className="flex flex-col gap-16 m-2">
            {/* Mobilawy Logo section : START */}
            <div className="flex justify-center">
              <img src={Logo} alt="HSBC Logo" className="max-w-1/2" />
            </div>
            {/* Mobilawy Logo section  :  END */}

            <section className="flex flex-col lg:flex-row gap-5 md:gap-10 xl:gap-52 items-center lg:justify-center">
              {/* Delete Card : START */}
              <div className="bg-white rounded-xl px-4 py-6 w-full max-w-lg lg:max-w-md">
                <div className="flex flex-col items-center gap-5">
                  {/* Card Title : START */}
                  <p className="text-primary text-lg font-extrabold text-center">
                    حذف الحساب
                  </p>
                  {/* Card Title  :  END */}

                  {/* Card contnet Outlet : START */}
                  <div className="w-full flex flex-col content-between justify-between min-h-52">
                    <AuthenticationProvider>
                      {steps[currentStep]}
                    </AuthenticationProvider>
                  </div>
                  {/* Card contnet Outlet : END */}

                  <CallCenter />
                </div>
              </div>
              {/* Delete Card :  END */}
            </section>
          </section>
        </div>
      </div>
    </div>
  );
}
