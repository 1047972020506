import React from "react";
import ReactDOM from "react-dom/client";
import "src/index.css";

import "src/i18n";
import DeleteAccount from "./pages/DeleteAccount";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

export const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <DeleteAccount />
    </QueryClientProvider>
  </React.StrictMode>,
);
