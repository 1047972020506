import Spinner from "./Spinner";

export default function FormButton({
  onClick,
  isDisabled,
  isLoading,
  btnText,
}) {
  return (
    <button
      className="relative bg-primary text-white w-full text-sm rounded-md py-3 disabled:bg-gray disabled:cursor-default cursor-pointer"
      type="button"
      disabled={isDisabled}
      onClick={() => (isDisabled ? undefined : onClick())}
    >
      {isLoading ? <Spinner /> : btnText}
    </button>
  );
}
